@import "../../scss/variables/Variables";

.form-item-radio {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    .form-field {
        position: relative;
        //padding: 18px 10px;
        font-family: $frutigerRegular;
        font-size: $copytext;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        order: 1;
        //height: 100%;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
        border: 1px solid $color-grey-20;
        transition: box-shadow 0.3s ease-out, border-color 0.3s ease-out,
            color 0.2s ease-out;

        &:before {
            content: "";
            float: left;
            padding-top: 100%; /* initial ratio of 1:1*/
        }

        &:hover {
            border: 1px solid $color-grey-100;
            box-sizing: border-box;
            box-shadow: $box-shadow;
        }

        &__input {
            position: absolute;
            left: -9999px;

            &:checked {
                ~ .form-field {
                    font-family: $frutigerRegular;
                    border: 1px solid $color-primary-red;
                    color: $color-primary-red;

                    .form-item-radio__sublabel-text {
                        font-family: $frutigerRegular;
                    }
                }
            }
        }

        &__info-box-list {
            padding: 0;
            margin: 0;
            list-style: none;

            &-item {
                white-space: pre-wrap;
                position: relative;
                padding-left: 12px;
            }
        }

        &__info-box-list-item {
            &:before {
                content: "\25A0";
                top: -1px;
                left: 0;
                position: absolute;
            }
        }

        .form-item-radio__label-circle {
            height: 64px;
            border-radius: 50%;
            margin: 0 auto 7px;
        }

        .form-item-radio__label-icon {
            width: 34px;
            height: 34px;
            fill: $color-primary-red;
        }

        .form-field-wrapper {
            position: absolute;
            top: 22%;
            transform: translateX(-50%);
            left: 50%;
            padding: 0 5px;
            width: 100%;
        }
    }

    &--fake-slide {
        .form-field {
            border: none;
            position: relative;
            color: $color-grey-60;
            margin-right: 1px;
            padding-right: 1px;

            &:after,
            &:before {
                content: "";
                display: none;
                position: absolute;
                left: 50%;
                transform: translateX(-50%) rotate(90deg) scale(1.1, 0.7);
                width: 0;
                height: 0;
                border-style: solid;
                float: none;
                padding: 0;
            }

            &:after {
                top: -3px;
                border-color: transparent transparent transparent $color-grey-20;
                border-width: 12px;
            }

            &:before {
                top: -2px;
                border-color: transparent transparent transparent $color-white;
                border-width: 13px;
            }

            &:hover {
                border: none;
                box-shadow: none;

                &:after,
                &:before {
                    display: block;
                }
            }

            .form-field-wrapper {
                padding: 0;
                position: static;
                transform: none;
                top: unset;

                &:before {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 12px;
                    float: none;
                    padding: 0;
                }

                &.first-of-type {
                    text-align: left;

                    &:before {
                        background-color: $color-grey-5;
                    }
                }

                &.second-of-type {
                    &:before {
                        background-color: $color-aquamarin-40;
                    }
                }

                &.third-of-type {
                    &:before {
                        background-color: $color-aquamarin-80;
                    }
                }

                &.fourth-of-type {
                    &:before {
                        background-color: $color-aquamarin-100;
                    }
                }

                &.fifth-of-type {
                    text-align: right;

                    &:before {
                        background-color: $color-onyx-100;
                    }
                }

                .form-item-radio__label-text {
                    transform: none;
                    position: static;
                }
            }

            &__input:checked {
                ~ .form-field {
                    border: none;
                    color: $color-grey-100;
                    font-family: $frutigerBold;

                    &:after,
                    &:before {
                        display: block;
                    }

                    &:after {
                        border-color: transparent transparent transparent
                            $color-grey-100;
                    }
                }
            }

            .form-item-radio__label-circle {
                height: 0;
            }
        }
    }

    &--long-box {
        .form-field {
            padding: 15px 24px;
            text-align: left;
            display: flex;
            align-items: center;

            &:before {
                content: none;
            }

            .form-item-radio__label-circle {
                display: flex;
                align-items: center;
                justify-content: left;
                height: 64px;
                border-radius: 50%;
                margin: 0 20px 0 0;
                flex-shrink: 0;
            }

            .form-item-radio__label-text {
                display: inline-block;
                white-space: pre-line;
            }
        }
    }

    &__sublabel-text {
        font-size: $copytext;
    }

    &:not(.form-item-radio--long-box) {
        .form-item-radio__label-text {
            position: absolute;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            white-space: pre-line;
        }
    }
}

.box-model {
    margin-bottom: 20px;

    &__margin-bottom {
        margin-bottom: 100px;
    }

    &--bottom-0 {
        margin-bottom: 0;
    }

    .row.box-model--additional-content {
        opacity: 0;
        height: 0;
        animation: show-additional-content 0.7s forwards;

        &.hidden {
            display: none;
        }
    }
}

@keyframes show-additional-content {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 100%;
    }
}

// IE11 only hack to show no before and a top border to the info-box
_:-ms-lang(x),
.form-item-radio .form-field__info-box {
    border-top: 1px solid $color-chocolate;

    &:after,
    &:before {
        content: none;
    }
}
