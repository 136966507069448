@import "../../scss/variables/Variables";

.subheadline {
    margin: 0 0 48px;
    font-family: $frutigerRegular;
    font-size: $copytext;

    &__no-margin {
        margin: 0;
    }
}
