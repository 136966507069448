@import "../../scss/variables/Variables";

.info-text {
    font-family: $frutigerRegular;
    font-size: $copytext;
    margin-top: 15px;

    &--flex {
        display: flex;

        &-space-between {
            justify-content: space-between; //working in comination with "isFlex" property
        }

        &__item {
            margin-right: 16px;

            &--dropdown {
                min-width: 165px;
            }

            &--text {
                padding-top: 18px;
            }
        }
    }

    &--no-margins {
        margin: 0;
    }

    &--under-form {
        margin-top: -8px;
        margin-bottom: 0;
    }

    &--small {
        font-size: 13px;
    }

    &__inline {
        display: inline-block;
        margin-right: 16px;
    }

    &__child {
        &--margin-down {
            margin-bottom: 28px;
        }
    }

    &--top-24 {
        margin-top: 24px;
    }

    &--top-30 {
        margin-top: 30px;
    }
}
