@import "../variables/Variables.scss";

.Dropdown {
    &-root {
        &.is-open {
            .Dropdown-svg-arrow {
                transform: rotate(0);
            }

            .Dropdown-placeholder {
                color: transparent;
                transition: none;

                &.is-selected {
                    color: $color-grey-100;
                }

                &:after {
                    background: $color-grey-100;
                }
            }

            ~ .form-item-text__label--placeholder {
                transform: translateY(-26px);
                font-size: 10px;
                opacity: 1;
                transition: transform 0.2s ease-out, font-size 0.2s ease-out,
                    opacity 0s 0s;
            }
            pointer-events: initial;
        }

        ~ .form-item-text__label--placeholder {
            transition: transform 0.2s ease-out, font-size 0.2s ease-out,
                opacity 0s 0.2s;
            opacity: 0;
            position: absolute;
            top: 0;
            pointer-events: none;

            &:after {
                position: absolute;
                content: attr(data-content);
                display: inline-block;
                width: 100px;
                height: 43px;
                background-color: $color-white;
                left: 0;
            }

            &.active {
                transition: none;
                transform: translateY(-26px);
                font-size: 10px;
                opacity: 1;
            }
        }
    }

    &-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-family: $frutigerRegular;
        font-size: $copytext;
        outline: none;
        background: $color-white;
        padding: 0 5px 10px 0;
        border: 0;
        border-radius: 0;
        width: 100%;
        box-sizing: border-box;
        overflow: visible;
    }

    &-menu {
        border: 0;
        margin-top: 2px;
        font-family: $frutigerRegular;
        line-height: 20px;
        box-shadow: none;
        top: auto;
        filter: drop-shadow(0px 2px 8px rgba(51, 51, 51, 0.15));
    }

    &-placeholder {
        transition: color 0s 0.2s;
        color: $color-grey-60;

        &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            background: $color-grey-60;
            width: 100%;
            height: 1px;
            z-index: 2;
        }

        &.is-selected {
            color: $color-grey-100;
            z-index: 2;
            transition: none;
        }
    }

    &-option {
        color: $color-grey-100;
        padding: 10px;

        &:first-child {
            padding-top: 10px;
            color: $color-grey-60;
        }

        &:last-child {
            padding-bottom: 10px;
        }

        &.is-selected {
            color: $color-primary-red;
            background-color: transparent;
        }

        &:hover {
            color: $color-primary-red;
            background-color: transparent;
        }

        &:empty {
            padding: 0;
        }
    }

    &-arrow-wrapper {
        width: 14px;
        height: 14px;
    }

    &-svg-arrow {
        transition: transform 0.2s ease-out;
        transform: rotate(180deg);
        position: relative;
        right: -5px;

        g {
            fill: $color-grey-100;
        }
    }
}
