@font-face {
    font-family: "DekaFrutiger W01 45 Light";
    src: url("../../fonts/b58e19da-2e56-4dc6-bab3-a4fc9f4acd31.eot?#iefix");
    src: url("../../fonts/b58e19da-2e56-4dc6-bab3-a4fc9f4acd31.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/06098ab6-7521-46ba-a744-8ffc7b88de5e.woff")
            format("woff"),
        url("../../fonts/6296f9bc-b8da-472a-9a94-711fd917c296.ttf")
            format("truetype"),
        url("../../fonts/7b266387-159d-459f-9598-77f881f80481.svg#7b266387-159d-459f-9598-77f881f80481")
            format("svg");
}
@font-face {
    font-family: "DekaFrutiger W01 55 Regular";
    src: url("../../fonts/407bf3a3-1104-47a2-a9c5-ce5ec3561d5a.eot?#iefix");
    src: url("../../fonts/407bf3a3-1104-47a2-a9c5-ce5ec3561d5a.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/c21bc1e5-a24a-4d1d-8505-95144c8c12ff.woff")
            format("woff"),
        url("../../fonts/263c1229-58e9-4d96-8ab7-6579d3bf5dfe.ttf")
            format("truetype"),
        url("../../fonts/adae365a-4c8a-4706-89b8-3c698a5e3fe3.svg#adae365a-4c8a-4706-89b8-3c698a5e3fe3")
            format("svg");
}
@font-face {
    font-family: "DekaFrutiger W01 56 Italic";
    src: url("../../fonts/e88691d5-6f96-4bd8-a2a4-cbd3a0e39cb6.eot?#iefix");
    src: url("../../fonts/e88691d5-6f96-4bd8-a2a4-cbd3a0e39cb6.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/02f6fd2e-7ec9-469b-a599-1777223d9cf1.woff")
            format("woff"),
        url("../../fonts/ed2c502e-e997-4800-99f9-56f58a37852c.ttf")
            format("truetype"),
        url("../../fonts/39afb245-9ec3-4cf4-a9b4-5d36459e6154.svg#39afb245-9ec3-4cf4-a9b4-5d36459e6154")
            format("svg");
}
@font-face {
    font-family: "DekaFrutiger W01 75 Bold";
    src: url("../../fonts/1481aa94-c523-4c52-a873-c5edae576ac6.eot?#iefix");
    src: url("../../fonts/1481aa94-c523-4c52-a873-c5edae576ac6.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/4858fe6b-7278-4750-9557-78ba37952861.woff")
            format("woff"),
        url("../../fonts/1841d5e7-125f-42ab-b2ee-4645b4e27020.ttf")
            format("truetype"),
        url("../../fonts/2bc9f7c7-65c8-4246-9116-42b487849f72.svg#2bc9f7c7-65c8-4246-9116-42b487849f72")
            format("svg");
}
@font-face {
    font-family: "DekaFrutiger W01 76 BoldItalic";
    src: url("../../fonts/4ead54e8-d651-40ae-9407-21f102f88bc2.eot?#iefix");
    src: url("../../fonts/4ead54e8-d651-40ae-9407-21f102f88bc2.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/22367c91-b6bc-4344-b178-5338d6f6e1bd.woff")
            format("woff"),
        url("../../fonts/20ff032b-4364-4eb8-8907-875cc7d2cf00.ttf")
            format("truetype"),
        url("../../fonts/4ae507b8-3d62-462f-aac9-208006df3744.svg#4ae507b8-3d62-462f-aac9-208006df3744")
            format("svg");
}
@font-face {
    font-family: "DekaFrutiger W01 46 Lig1370250";
    src: url("../../fonts/80919c9b-b839-4b7e-8e47-1d0bc989539e.eot?#iefix");
    src: url("../../fonts/80919c9b-b839-4b7e-8e47-1d0bc989539e.eot?#iefix")
            format("embedded-opentype"),
        url("../../fonts/b79e57af-453f-45f5-b214-609ee6e0e767.woff")
            format("woff"),
        url("../../fonts/808b46b2-aa95-4852-8af7-a8808c409a41.ttf")
            format("truetype"),
        url("../../fonts/ef329f2b-d171-47e0-82b9-939a8ffbc4be.svg#ef329f2b-d171-47e0-82b9-939a8ffbc4be")
            format("svg");
}

@font-face {
    font-family: "PlayfairDisplay";
    src: url("../../fonts/playfairdisplay.eot?#iefix");
    src: url("../../fonts/playfairdisplay.eot?#iefix") format("eot"),
        url("../../fonts/playfairdisplay.woff") format("woff"),
        url("../../fonts/playfairdisplay.ttf") format("truetype"),
        url("../../fonts/playfairdisplay.svg#playfairdisplay") format("svg");
}
