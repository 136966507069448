@import "../variables/Variables.scss";

html {
    scroll-behavior: smooth;
}

body {
    font-family: $frutigerRegular;
    font-weight: normal;
    font-size: $copytext;
    line-height: 24px;
    min-height: 100%;
    margin: 0;
    padding: 0;
    color: $color-grey-100;
    overflow: hidden;
}

.site {
    width: 1260px;
    position: relative;
    margin: 0 auto;
    padding-right: 10px;
    box-sizing: border-box;
    transform: translateY(100%);
    transition: transform 0.7s ease-out;
    overflow: hidden;

    &--show {
        transform: translateY(0);
    }

    .container-fluid {
        padding-right: 0;
        padding-left: 0;

        .row {
            margin-right: -10px;
            margin-left: -10px;
            margin-bottom: 15px;

            &--bottom-0 {
                margin-bottom: 0;
            }

            &--bottom-20 {
                margin-bottom: 20px;
            }

            &--bottom-24 {
                margin-bottom: 24px;
            }

            &--bottom-48 {
                margin-bottom: 48px;
            }

            &--bottom-60 {
                margin-bottom: 60px;
            }

            &--bottom-68 {
                margin-bottom: 68px;
            }

            &--bottom-100 {
                margin-bottom: 100px;
            }

            &--bottom-120 {
                margin-bottom: 120px;
            }

            &--optional {
                max-height: 0;
                overflow: hidden;
                box-sizing: border-box;
                animation: hideRow 0.3s forwards ease-out;
            }

            &--optional-active {
                animation: showRow 0.3s forwards ease-out;
                margin-top: 0;
                margin-bottom: 15px;
                overflow: visible;
            }

            &--no-margin {
                margin-left: 0;
                margin-right: 0;
            }
        }

        [class^="col"] {
            padding-left: 10px;
            padding-right: 10px;

            &.align-right {
                text-align: right;
                padding: 0;
            }

            &.no-padding {
                padding: 0;
            }
        }

        //if 5 tiles in a row makes all of them 20% * 5 = 100%
        .col-2_4 {
            flex-basis: 20%;
            max-width: 20%;
        }

        //if 5 tiles in a row makes all of them 16.6666% so they can in total work as a 10 col container
        .col-1-6 {
            flex-basis: 16.66666%;
            max-width: 16.66666%;
        }

        &.bottom-sticky {
            position: absolute;
            bottom: 21px;
            right: 0;
        }
    }
}

.main-content {
    height: 100%;

    &__wrapper {
        position: relative;
        height: calc(100vh - 146px);
    }
}

.parallax-layer {
    z-index: 0;
    width: 100%;
    height: calc(100vh - 245px);
    overflow: auto;
    padding: 0 35px 0 10px;

    &--static {
        position: static;
    }

    &.is-last-page,
    &.full-overlay {
        height: calc(100vh - 146px);
    }

    .additional-investment-field {
        display: none;
        margin-top: 60px;

        &--show {
            display: block;
        }
    }
}

.full-overlay {
    text-align: center;
    position: static;
    top: 147px;
    left: 0;
    width: 100%;
    z-index: 5;
    padding: 0;
    margin: 0;
    transition: all 0.7s ease-out;

    &.full-overlay--disapear {
        position: absolute;
        top: -100%;
    }
}

.back-to-summary {
    margin-right: 20px;
}

@keyframes showRow {
    0% {
        max-height: 0;
    }
    100% {
        max-height: 100px;
        overflow: visible;
    }
}

@keyframes hideRow {
    0% {
        max-height: 100px;
        overflow: hidden;
        margin-bottom: 15px;
    }
    100% {
        max-height: 0;
    }
}
