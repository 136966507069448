@import "../../scss/variables/Variables";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 20;
    animation-name: show-modal;
    animation-duration: 0.5s;

    &:first-child {
        background: transparent;
    }

    &:last-child {
        background: rgba(0, 0, 0, 0.6);
    }

    &__wrapper {
        position: fixed;
        width: 536px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $color-white;
        padding: 48px;
        box-sizing: border-box;
    }

    &__close {
        position: absolute;
        right: 21px;
        top: 21px;
        cursor: pointer;
        height: 21px;
        width: 21px;

        path {
            fill: $color-grey-100;
        }

        &:hover {
            path {
                fill: $color-primary-red;
            }
        }
    }

    &__content {
        display: flex;
        text-align: left;
        box-sizing: border-box;

        &-loader {
            display: inline-flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-shrink: 0;
            width: 40px;
            height: 35px;
            margin-right: 20px;

            ~ .modal__content-inner {
                display: flex;
                align-items: center;

                .modal__content-inner-headline {
                    margin-bottom: 0;
                }
            }
        }

        &-inner {
            display: inline-block;

            &--no-copytext {
                display: flex;
                align-items: center;
            }
        }

        &-inner-headline {
            font-family: $frutigerRegular;
            font-size: $sidebar;
            margin-bottom: 24px;
            line-height: 30px;
        }

        &-inner-text {
            font-family: $frutigerRegular;
            font-size: $copytext;

            overflow-y: auto;
            max-height: 480px;
            white-space: pre-line;

            .link-standard {
                margin-top: 0;
            }
        }
    }

    &__button-wrapper {
        margin-top: 24px;
        text-align: right;

        [class^="button-"] {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .form-field__info-box-list {
        padding-left: 15px;
    }
}

@keyframes show-modal {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
