@import "../../scss/variables/Variables";

.main-navigation {
    ul {
        list-style: none;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: $color-black;
    }

    .main-navigation__subitem {
        padding: 11px 0;
        font-size: 13px;
        line-height: 18px;
        font-family: $frutigerBold;
        transform: translateX(0);
        display: block;
        transition: all 0.5s ease-out;

        &:first-of-type {
            border-top: 1px solid $color-grey-20;
            padding-top: 19px;
        }

        &--hidden {
            transform: translateX(-300px);
            height: 0;
            padding: 0;
            overflow: hidden;
        }

        &--disabled {
            color: $color-grey-40;

            .main-navigation__subitem-text {
                cursor: default;
            }
        }

        &.active {
            font-family: $frutigerBold;

            .main-navigation__subitem-arrow {
                margin-left: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-text,
        &-additional {
            display: inline-block;
        }

        &-text {
            cursor: pointer;
        }

        &-arrow {
            display: inline-block;
            margin-right: 10px;
            top: 2px;
            position: relative;
            transform: rotate(90deg);
            margin-left: -24px;
            transition: margin-left 0.2s ease-out;
        }

        &-additional {
            float: right;

            svg {
                width: 14px;
                height: 19px;
            }
        }
    }
}
