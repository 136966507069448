@import "../../scss/variables/Variables";

.text-checkbox {
    display: inline-block;

    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
    }

    &__input {
        position: absolute;
        left: -9999px;
        opacity: 0;

        &:checked {
            ~ .text-checkbox__checkbox {
                position: relative;

                .text-checkbox__icon {
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -55%);
                    width: 70%;
                    height: 70%;

                    path {
                        fill: $color-primary-red;
                    }
                }
            }
        }
    }

    &__checkbox {
        display: inline-block;
        width: 24px;
        height: 24px;
        border: 1px solid $color-grey-60;
        background: $color-white;
        margin-right: 12px;
        padding: 1px;
        box-sizing: border-box;
    }

    &__icon {
        display: none;
    }

    &__text {
        font-family: $frutigerBold;
        font-size: $copytext;
    }

    &--musterportfolios {
        margin-bottom: 48px;
    }

    &--bottom-14 {
        margin-bottom: 14px;
    }
}
