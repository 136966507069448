@import "../../scss/variables/Variables";

.header {
    position: relative;
    z-index: 10;
    height: 146px;
    width: 100%;
    background-color: $color-white;

    &__content {
        position: relative;
        max-width: 1240px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;

        &-logo-no-bg {
            width: 250px;
            height: 59px;

            path {
                fill: $color-grey-100;
            }
        }

        &-logo--right-side {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 2px;

            .logo-wrapper {
                display: flex;
                flex-direction: column;

                &--text {
                    text-align: right;
                    font-size: 11px;
                }
            }
        }

        &-logo--left-side {
            height: 50px;

            svg {
                height: 100%;
                fill: $color-primary-red;

                &.logo-dusseldorf {
                    height: 37px;
                }

                &.logo-schwaebisch-hall-crailsheim,
                &.logo-baden-baden-gaggenau {
                    position: relative;
                    top: -10px;
                    height: 60px;
                }
            }
        }

        &-pipe,
        &-text {
            font-family: $frutigerRegular;
            font-size: 20px;
            color: $color-grey-100;
            line-height: 30px;
        }

        &-pipe {
            margin: -8px 24px 0;
            color: $color-grey-20;
            width: 1px;
        }
    }
}
