@import "../../scss/variables/Variables";

.button {
    &-primary,
    &-secondary {
        display: inline-block;
        font-family: $frutigerBold;
        font-size: $copytext;
        padding: 14px 24px;
        line-height: 20px;
        text-decoration: none;
        text-align: center;
        border: 0;
        outline: none;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &.button-next {
            margin-top: 5px;
        }

        &.button--end-of-page {
            margin-bottom: 20px;
        }

        &.button--disabled {
            border: 0;
            cursor: default;
            opacity: 0.5;

            &:after {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='12px' viewBox='0 0 50 37' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M44.1545976,19.9719626 L2.41818182,19.9719626 C1.63494163,19.9719626 1,19.3373507 1,18.5545171 C1,17.7716836 1.63494163,17.1370717 2.41818182,17.1370717 L44.2636319,17.1370717 L30.5468117,3.42025146 C29.9932648,2.86670457 29.9934979,1.96899462 30.5473324,1.41516017 C31.1011668,0.86132572 31.9988768,0.861092609 32.5524237,1.4146395 L48.580461,17.4426769 C48.8711923,17.7334081 49.0091628,18.1190771 48.9943983,18.5 C49.0091628,18.8809229 48.8711923,19.2665919 48.580461,19.5573231 L32.5524237,35.5853605 C31.9988768,36.1389074 31.1011668,36.1386743 30.5473324,35.5848398 C29.9934979,35.0310054 29.9932648,34.1332954 30.5468117,33.5797485 L44.1545976,19.9719626 Z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
            }

            &:hover {
                background: $color-grey-100;
            }
        }

        &:after {
            content: "";
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='12px' viewBox='0 0 50 37' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M44.1545976,19.9719626 L2.41818182,19.9719626 C1.63494163,19.9719626 1,19.3373507 1,18.5545171 C1,17.7716836 1.63494163,17.1370717 2.41818182,17.1370717 L44.2636319,17.1370717 L30.5468117,3.42025146 C29.9932648,2.86670457 29.9934979,1.96899462 30.5473324,1.41516017 C31.1011668,0.86132572 31.9988768,0.861092609 32.5524237,1.4146395 L48.580461,17.4426769 C48.8711923,17.7334081 49.0091628,18.1190771 48.9943983,18.5 C49.0091628,18.8809229 48.8711923,19.2665919 48.580461,19.5573231 L32.5524237,35.5853605 C31.9988768,36.1389074 31.1011668,36.1386743 30.5473324,35.5848398 C29.9934979,35.0310054 29.9932648,34.1332954 30.5468117,33.5797485 L44.1545976,19.9719626 Z' fill='%23ffffff'%3E%3C/path%3E%3C/svg%3E");
            background-repeat: no-repeat;
            display: inline-block;
            width: 14px;
            height: 12px;
            margin-left: 12px;
            transition: all 0.3s ease-out;
        }

        &:not(.button--disabled):hover:after {
            margin-left: 16px;
        }
    }

    &-primary {
        background: $color-grey-100;
        color: $color-white;

        &:hover {
            background: $color-primary-red;
        }
    }

    &-secondary {
        background: $color-white;
        color: $color-grey-100;
        border: 1px solid $color-grey-100;
        padding: 13px 24px;

        &:after {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='12px' viewBox='0 0 50 37' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath d='M44.1545976,19.9719626 L2.41818182,19.9719626 C1.63494163,19.9719626 1,19.3373507 1,18.5545171 C1,17.7716836 1.63494163,17.1370717 2.41818182,17.1370717 L44.2636319,17.1370717 L30.5468117,3.42025146 C29.9932648,2.86670457 29.9934979,1.96899462 30.5473324,1.41516017 C31.1011668,0.86132572 31.9988768,0.861092609 32.5524237,1.4146395 L48.580461,17.4426769 C48.8711923,17.7334081 49.0091628,18.1190771 48.9943983,18.5 C49.0091628,18.8809229 48.8711923,19.2665919 48.580461,19.5573231 L32.5524237,35.5853605 C31.9988768,36.1389074 31.1011668,36.1386743 30.5473324,35.5848398 C29.9934979,35.0310054 29.9932648,34.1332954 30.5468117,33.5797485 L44.1545976,19.9719626 Z' fill='%23ff0000'%3E%3C/path%3E%3C/svg%3E");
        }

        &:hover {
            color: $color-primary-red;
            border-color: $color-primary-red;
        }

        &.button--disabled {
            color: $color-grey-40;
        }
    }

    &__no-icon {
        &:after {
            content: none;
        }
    }

    &__import-icon {
        &:after {
            content: none;
        }

        .keyvisual-content__import-icon {
            path {
                fill: $color-white;
            }
        }
    }
}
