@import "../../scss/variables/Variables";

.textarea-content-wrapper {
    position: relative;

    .form-item-text--error-message {
        position: relative;
        display: flex;
        align-items: center;
        background: $color-red-error;
        font-size: 13px;
        font-family: $frutigerRegular;
        color: $color-primary-red;
        padding: 16px;
        margin-top: 24px;

        .error-icon {
            height: 24px;
            width: 28px;
            margin-right: 16px;
            align-self: baseline;
            position: relative;
            top: -3px;
        }

        &-inline {
            display: inline-block;
        }
    }
}

.textarea-mask {
    background: $color-white;
    word-break: break-word;
    margin-top: 36px;
    width: 100%;
    outline: none;
    font-family: $frutigerLight;
    font-size: $copytext;
    line-height: 20px;
    color: $color-grey-100;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;

    &:focus,
    &.textarea-mask--active {
        color: $color-grey-100;
    }

    &:empty:not(:focus)::before {
        content: attr(data-placeholder);
    }

    &__tag {
        display: inline-block;
    }

    &__tag:not(.textarea-mask__characters) {
        background: $color-grey-5;
        color: $color-grey-100;
        padding: 0 32px 0 8px;
        margin: 0 5px 5px 0;
        position: relative;
        word-break: break-word;
        user-select: none;
        font-family: $frutigerLight;
        z-index: 2;
        font-size: $copytext;
        line-height: 40px;

        .textarea-mask__tag-x {
            position: absolute;
            color: $color-white;
            top: 12px;
            right: 11px;
            cursor: pointer;
            z-index: 3;
            transform: scale(0.6);

            path {
                fill: $color-grey-100;
                width: 9px;
            }
        }
    }
}

.textarea-mask__characters {
    color: $color-grey-100;
    word-break: break-word;
}

.textarea-content-editable {
    resize: none;
    background: transparent;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid $color-grey-60;
    word-break: break-word;
    width: 100%;
    outline: none;
    font-family: $frutigerLight;
    font-size: $copytext;
    word-spacing: 2.69em;
    color: transparent;
    padding: 0;
    box-sizing: border-box;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    caret-color: $color-grey-100;

    &.textarea-content-editable__line-height {
        line-height: 2.8em;
    }

    &:focus {
        border-bottom-color: $color-grey-100;
    }
}
