@import "../../scss/variables/Variables";

.info-box {
    padding: 16px 48px 16px 16px;
    background-color: $color-aquamarin-10;
    animation: fadeIn 0.6s ease-out;
    display: flex;
    align-items: center;

    &--hidden {
        display: none;
    }

    &__has-info-icon {
        font-size: 13px;
    }

    &__info-icon {
        height: 22px;
        width: 22px;
        margin-right: 17px;

        path {
            fill: $color-grey-100;
        }
    }

    &__list {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            white-space: pre-wrap;
            position: relative;
            padding-left: 24px;
            margin-left: 18px;

            &:not(:last-child) {
                margin-bottom: 12px;
            }

            &:before {
                content: "\25CF";
                top: -1px;
                left: 0;
                position: absolute;
                color: $color-aquamarin-100;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
