@import "../../scss/variables/Variables";

.link-standard {
    color: $color-primary-red;
    cursor: pointer;
    font-family: $frutigerRegular;

    &--bold {
        display: block;
        font-family: $frutigerBold;
        margin-top: 16px;
    }

    &--is-inline {
        display: inline-block;
    }

    &--disabled {
        color: $color-grey-60;
        cursor: auto;
    }

    &--form-option {
        margin: 0;
    }

    &--dark {
        color: $color-grey-100;

        &:hover {
            color: $color-primary-red;
        }
    }

    svg {
        position: relative;
        top: 2px;
        margin-left: 5px;
    }
}
