@import "../../scss/variables/Variables";

.main-navigation {
    width: 100%;
    position: relative;
    margin-top: 100px;
    transform: translateY(-550px);
    transition: transform 0.7s ease-out;

    &--disabled {
        color: $color-grey-40;
    }

    &.active {
        transform: translateY(0);
    }

    &__sublist {
        color: $color-black;
        max-height: 0;
        overflow: hidden;
        transition: max-height 1s ease-out;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: $color-black;
    }

    &__item {
        font-family: $frutigerBold;
        font-size: $sidebar;
        margin: 0 0 16px 0;
        border-bottom: 1px solid $color-grey-20;

        &:first-child {
            border-top: 0;
        }

        &--active {
            color: $color-primary-red;

            &:not(.main-navigation__item--no-subitems) {
                padding: 0 0 8px 0;
            }

            .main-navigation__item-label-arrow {
                transform: rotate(0);

                path {
                    fill: $color-primary-red;
                }
            }

            .main-navigation__sublist {
                max-height: 100vh;
            }
        }

        &-label {
            cursor: pointer;
            display: flex;
            padding-bottom: 16px;
            justify-content: space-between;
            align-items: center;

            &-arrow {
                position: absolute;
                right: 0;
                align-self: center;
                height: 20px;
                width: 20px;
                transition: transform 0.2s ease-out;
                transform: rotate(180deg);

                path {
                    fill: $color-black;
                }
            }

            &-check {
                height: 20px;
                width: 22px;
            }
        }

        &-checkbox {
            position: absolute;
            opacity: 0;
            left: -9999px;

            &:checked {
                ~ .main-navigation__sublist {
                    display: block;
                }
            }
        }

        .main-navigation--disabled {
            cursor: default;

            .main-navigation__item-label-arrow,
            .main-navigation__item-label-minus {
                path {
                    fill: $color-grey-100;
                }
            }
        }
    }
}
