@import "../../scss/variables/Variables";

.bars-wrapper {
    height: 35px;
    position: relative;

    .bar1,
    .bar2,
    .bar3 {
        background-color: $color-primary-red;
        width: 12px;
        margin-right: 2px;
        bottom: 0;
        position: absolute;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    .bar1 {
        animation-name: animation-bar1;
        left: 0;
    }
    .bar2 {
        animation-name: animation-bar2;
        left: 15px;
    }
    .bar3 {
        animation-name: animation-bar3;
        left: 30px;
    }
}

@keyframes animation-bar1 {
    0% {
        height: 0;
    }
    10% {
        height: 15px;
    }
    90% {
        height: 15px;
    }
    100% {
        height: 0;
    }
}

@keyframes animation-bar2 {
    0% {
        height: 0;
    }
    30% {
        height: 25px;
    }
    90% {
        height: 25px;
    }
    100% {
        height: 0;
    }
}

@keyframes animation-bar3 {
    0% {
        height: 0;
    }
    40% {
        height: 35px;
    }
    90% {
        height: 35px;
    }
    100% {
        height: 0;
    }
}
