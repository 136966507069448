@import "../../scss/variables/Variables";

.accordion-wrapper {
    font-family: $frutigerRegular;
    font-size: $copytext;

    &--hidden {
        display: none;
    }

    &:first-child {
        .accordion__headline {
            padding-top: 0;
        }
    }

    .accordion__headline {
        font-family: $frutigerBold;
        display: flex;
        position: relative;
        cursor: pointer;
        padding: 10px 34px 10px 0;
        text-align: left;
        outline: none;
        transition: 0.4s;
        background: none;

        &-arrow {
            position: absolute;
            right: 0;
            align-self: center;
            transition: transform 0.2s ease-out;
            transform: rotate(180deg);
            z-index: -1;
        }

        &-plus {
            height: 21px;
            width: 21px;
            margin: 0 11px 0 17px;
            position: relative;
            top: 3px;
        }

        &-minus {
            height: 30px;
            width: 30px;
            margin: 0 6px 0 13px;
            position: relative;
            top: 8px;
        }

        &.is-selected {
            .accordion__headline-checkbox {
                position: relative;
            }

            .accordion__headline-checkbox-icon {
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 70%;
                height: 70%;

                path {
                    fill: $color-primary-red;
                }
            }
        }

        &.active {
            border: 0;
            padding-bottom: 24px;

            ~ .accordion__panel {
                padding: 0 0 72px 36px;
                overflow: visible;
                opacity: 1;
            }

            .accordion__headline-arrow {
                transform: rotate(0);
            }
        }

        &-checkbox {
            display: inline-block;
            width: 24px;
            height: 24px;
            border: 1px solid $color-grey-60;
            background: $color-white;
            margin-right: 12px;
            padding: 1px;
            cursor: pointer;
            box-sizing: border-box;

            &-icon {
                display: none;
            }
        }

        &-text {
            display: inline-block;
            width: 100%;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background: transparent;
                width: 100%;
                height: 100%;
                margin-left: 35px;
            }
        }

        &-input {
            position: absolute;
            opacity: 0;
            left: -9999px;
        }
    }

    .accordion__panel {
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        opacity: 0;
        padding-left: 36px;

        .info-text {
            white-space: nowrap;
        }

        .info-text--normal {
            white-space: normal;
        }
    }

    &:last-of-type {
        .accordion__headline.active ~ .accordion__panel {
            padding: 0 0 12px 36px;
        }
    }
}

.accordion-wrapper--big-headline {
    .accordion__headline {
        font-family: $frutigerRegular;
        font-size: $sidebar;
        line-height: 62px;
        height: 62px;
        padding: 0;
        background-color: $color-grey-5;
        margin-bottom: 16px;

        &.active {
            padding: 0;
            margin-bottom: 32px;

            ~ .accordion__panel {
                padding: 0;
            }
        }
    }

    .accordion__panel {
        padding: 0;
    }

    .accordion__headline-checkbox {
        display: none;
    }

    .accordion-list-item {
        &:first-child {
            border-top: none;
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 32px;
        }
    }
}

.accordion-list-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 1px solid $color-grey-20;
    padding: 12px 0;

    &__left {
        width: 35%;
    }

    &__right {
        width: 65%;
        text-align: right;
    }

    &__arrow {
        position: relative;
        top: 2px;
        margin-right: 5px;
        transform: rotate(90deg);
    }

    &__field {
        display: inline-block;

        &:after {
            content: "|";
            padding: 0 5px;
            position: relative;
        }

        &:last-child {
            &:after {
                content: none;
            }
        }

        &:empty {
            display: none;
        }
    }

    &:last-child {
        padding: 10px 0 0 0;
    }

    .ellipsis-derivation-field {
        max-height: 55px;
    }

    &__from-checkbox {
        margin-top: 5px;
    }

    &__check {
        margin-right: 5px;
        position: relative;
        top: 3px;
        width: 14px;
        height: 17px;

        path {
            fill: $color-grey-100;
        }
    }

    &__separator {
        display: block;

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }
}
.accordion-list-item__no-border {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-top: 0;
    padding: 0 0 10px 0;

    &:last-child {
        padding: 0;
    }

    &--left {
        width: 65%;
    }

    &--right {
        width: 35%;
        text-align: right;
        align-self: flex-end;
    }
}
