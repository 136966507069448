@import "../config/Fonts";

$color-primary-red: #ff0000;
$color-white: #fff;
$color-black: #000000;
$color-grey-100: #333;
$color-grey-60: #909090;
$color-grey-40: #b6b6b6;
$color-grey-20: #dbdbdb;
$color-grey-10: #eeeeee;
$color-grey-5: #f6f6f6;
$color-chocolate: #cebeb9;
$color-onyx-100: #2a413f;
$color-aquamarin-100: #569196;
$color-aquamarin-80: #78a7ab;
$color-aquamarin-40: #bbd3d5;
$color-aquamarin-10: #eef4f5;
$color-red-error: #fdf3f2;

$frutigerLight: "DekaFrutiger W01 45 Light";
$frutigerRegular: "DekaFrutiger W01 55 Regular";
$frutigerItalic: "DekaFrutiger W01 56 Italic";
$frutigerBold: "DekaFrutiger W01 75 Bold";
$frutigerBoldItalic: "DekaFrutiger W01 76 BoldItalic";
$frutigerLig: "DekaFrutiger W01 46 Lig1370250";
$playFairDisplay: "PlayfairDisplay";

$headline1: 60px;
$headline2: 44px;
$headline3: 31px;
$sidebar: 20px;
$copytext: 15px;
$footnotes: 13px;

$box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.15);
$box-shadow-arrow-top: 3px 3px 6px 0 rgba(0, 0, 0, 0.15);
$box-shadow-arrow-bottom: -3px -3px 6px 0 rgba(0, 0, 0, 0.15);
