@import "../variables/Variables.scss";

.range-slider {
    margin: 0 0 36px 0;

    &.rc-slider {
        width: calc(100% - 20px);
        transform: translateX(2px);
    }

    .rc-slider-rail {
        background-color: $color-grey-60;
        height: 1px;
        border-radius: 0;
    }

    .rc-slider-step {
        &:hover {
            cursor: pointer;
        }
    }

    .rc-slider-track {
        background-color: $color-grey-100;
        height: 1px;
        border-radius: 0;
    }

    .rc-slider-dot {
        border: 1px solid $color-grey-60;
        width: 24px;
        height: 24px;
        bottom: -8px;

        &-active {
            border: 1px solid $color-grey-100;
        }
    }

    .rc-slider-handle {
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: $color-primary-red;
            border-radius: 50%;
            left: 15px;
            top: 4px;
        }
    }

    .rc-slider-handle,
    .rc-slider-handle:hover,
    .rc-slider-handle:focus,
    .rc-slider-handle-click-focused {
        margin-top: -9px;
        width: 24px;
        height: 24px;
        box-shadow: none;
        border: none;
        background-color: transparent;
    }

    .rc-slider-mark {
        top: 20px;
        left: 7px;
        font-size: $copytext;
        font-family: $frutigerRegular;
        margin-top: 14px;

        &-text {
            color: $color-grey-60;
            width: 130px;

            &:first-child {
                text-align: left;
                transform: translateX(-5%) !important;
            }

            &:last-child {
                text-align: right;
                transform: translateX(-93%) !important;
            }

            &-current {
                font-family: $frutigerBold;
                color: $color-grey-100;
            }
        }
    }

    &--short-labels {
        margin: 0 0 20px 0;

        .rc-slider-mark-text {
            width: auto;

            &:first-child {
                transform: translateX(-8px) !important;
            }

            &:last-child {
                transform: translateX(-30px) !important;
            }
        }
    }

    &--small-dots {
        &.rc-slider {
            width: calc(100% - 10px);
            transform: translateX(5px);

            &-track {
                background-color: $color-primary-red;
            }
        }

        .rc-slider-track {
            background-color: $color-primary-red;
        }

        .rc-slider-dot {
            height: 10px;
            width: 10px;
            bottom: -2px;

            &-active {
                border-color: $color-primary-red;
            }
        }

        .rc-slider-handle {
            &:before {
                content: "";
                width: 24px;
                height: 24px;
                position: absolute;
                border: 1px solid $color-primary-red;
                border-radius: 50%;
                top: -4px;
                left: -0;
                background-color: $color-white;
            }

            &:after {
                left: 8px;
            }
        }

        .rc-slider-mark {
            transform: translateX(0);
            left: 0;
        }

        .rc-slider-mark-text {
            &:first-child {
                transform: none !important;
            }

            &-active {
                color: $color-grey-100;
            }
        }
    }
}
