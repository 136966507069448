@import "../../scss/variables/Variables";

.main-progress {
    height: calc(100vh - 146px);
    transform: translateX(50%);

    .progress-bar {
        background: $color-grey-10;
        width: 3px;
        height: 100%;
        top: 0;

        &__status {
            background-color: $color-primary-red;
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            transition: all 0.5s ease-out;
        }
    }
}
