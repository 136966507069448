$scroll-time: 700ms;
// must always be in ms

.main-content .scrollNext,
.main-content .scrollBack {
    animation: $scroll-time forwards ease-out;

    .parallax-layer {
        overflow: visible;
        position: relative;
        height: calc(100vh - 146px);
    }

    .bottom-sticky {
        right: 45px;
    }
}

.main-content .scrollNext {
    overflow: visible;
    animation-name: moveNext;
    height: auto;
}

.main-content .scrollBack {
    animation-name: moveBack;
    height: auto;
    overflow: visible;

    .bottom-sticky {
        bottom: 25px;
    }
}

.main-content .scrollNext section:nth-child(1) {
    transform: translateY(100%);
}
.main-content .scrollNext section:nth-child(2) {
    transform: translateY(-100%);
}

@keyframes moveNext {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-50%);
    }
}

@keyframes moveBack {
    from {
        transform: translateY(-50%);
    }
    to {
        transform: translateY(0);
    }
}

:export {
    scrollTime: $scroll-time;
}
