@import "../../scss/variables/Variables";

.keyvisual {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    position: relative;
    clip-path: polygon(20% 0, 0 100%, 100% 100%, 100% 0);
    margin-right: calc(((100vw - 1230px) / 2) * -1);

    @media screen and (max-width: 1230px) {
        margin-right: 0;
    }

    &__wrapper {
        display: flex;
        height: 100%;
        max-width: 1230px;
        margin: 0 auto;
        overflow-x: visible;
        overflow-y: clip;

        &--left {
            width: 60%;
        }

        &--right {
            width: 40%;
        }
    }

    &-headline {
        font-family: $playFairDisplay;
        font-size: $headline1;
        line-height: 75px;
        margin-bottom: 72px;
        white-space: pre-wrap;
        position: relative;
        z-index: 1;

        &:before {
            content: "";
            display: block;
            width: 48px;
            height: 12px;
            background-color: red;
            margin-bottom: 24px;
        }
    }

    &-subheadline {
        font-family: $frutigerRegular;
        font-size: $headline3;
        line-height: 37px;
        margin-bottom: 48px;

        /*        @media screen and (max-height: 836px) {
            font-size: 26px;
            margin-bottom: 32px;
            line-height: 34px;
        }*/
    }

    &-content__top {
        width: 100%;
        text-align: left;
        color: $color-grey-100;
    }

    &-content__btn-wrapper {
        text-align: left;
        display: flex;
    }

    &-content__import {
        display: flex;
        margin-left: 48px;
        justify-content: center;
        align-items: center;
        font-family: $frutigerBold;
        cursor: pointer;
        color: $color-grey-100;

        &:hover {
            color: $color-primary-red;
        }

        &-icon {
            margin-left: 12px;
            width: 16px;
            height: 16px;

            path {
                fill: $color-primary-red;
            }
        }
    }

    &-content__footer {
        position: absolute;
        bottom: 37px;

        @media screen and (max-height: 845px) {
            position: static;
            text-align: left;
            margin-top: 30px;
        }

        &--text {
            font-size: 13px;
            display: inline-block;

            &:first-of-type {
                position: relative;

                /*
                // removed because only one keyvisual footer text is need. but maybe in future we will have another one so we keep the CSS
                &:after {
                    content: "";
                    position: relative;
                    top: 8px;
                    margin: 0 17px 0 16px;
                    display: inline-block;
                    height: 24px;
                    border-right: 1px solid $color-grey-20;
                }
                */
            }
        }
    }
}

.button__import-icon {
    position: relative;

    .keyvisual-content__import-icon {
        position: relative;
        top: 2px;
    }
}
