@import "../../scss/variables/Variables";

.form-item-text {
    display: block;
    position: relative;
    margin-bottom: 15px;

    &--inline {
        display: inline-block;
        margin: 0 10px;

        &.no-margins {
            margin: 0;
        }
    }

    &--absolute-error {
        position: relative;
        margin-bottom: 60px;

        .form-item-text__input--error ~ .form-item-text--error-message {
            position: absolute;
            white-space: nowrap;
        }
    }

    &__label,
    &__input {
        display: block;
        cursor: pointer;
    }

    &__label {
        font-family: $frutigerRegular;
        line-height: 20px;

        &--empty {
            margin-bottom: 19px;
            display: block;
        }

        &--placeholder {
            position: relative;
            top: 21px;
            opacity: 0;
            color: $color-grey-60;
            cursor: text;
            transition: transform 0.2s ease-out, opacity 0s ease-out 0.2s,
                font-size 0.2s ease-out;

            ~ input::placeholder {
                transition-property: opacity;
                transition-delay: 0.2s;
            }

            &.active {
                transform: translateY(-26px);
                transition: transform 0.2s ease-out, font-size 0.2s ease-out;
                opacity: 1;
                font-size: 10px;

                ~ input::placeholder {
                    opacity: 0;
                    transition-delay: 0s;
                }
            }
        }
    }

    &__input {
        font-family: $frutigerRegular;
        font-size: $copytext;
        line-height: 20px;
        outline: none;
        background: #fff;
        padding-bottom: 13px;
        padding-left: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid $color-grey-60;
        border-left: 0;
        cursor: text;
        width: 100%;
        box-sizing: border-box;

        &:focus {
            border-bottom: 1px solid $color-grey-100;
        }

        &::placeholder {
            color: $color-grey-60;
        }

        &--inline-date {
            display: inline-block;
            width: 90px;
        }

        &--percentage {
            position: relative;
            display: inline-block;
        }

        &--percentage::after {
            position: absolute;
            right: 5px;
            top: 1px;
            content: attr(data-placeholder);
            pointer-events: none;
            opacity: 0.9;
        }
    }

    &__max-investment {
        margin-left: 35px;

        ~ .form-item-text--error-message {
            margin-left: 23px;
            width: 130%;
        }
    }

    &--offer-date {
        width: 191px;
        display: inline-block;
        margin-right: 24px;
    }
}

.form-item-text__input--error {
    border-bottom: 2px solid $color-primary-red;
    color: $color-primary-red;

    ~ .form-item-text--error-message {
        white-space: pre-wrap;
        position: relative;
        display: flex;
        align-items: center;
        background: $color-red-error;
        font-size: 13px;
        font-family: $frutigerRegular;
        color: $color-primary-red;
        padding: 16px;
        border-radius: 2px;
        margin-top: 24px;

        .error-icon {
            height: 24px;
            width: 28px;
            margin-right: 16px;
            align-self: baseline;
            position: relative;
            top: -3px;
        }
    }

    &:focus {
        border-color: $color-primary-red;
    }
}

.form-item-text--error-message {
    display: none;
}

.input-wrapper {
    position: relative;

    &__percentage {
        .form-item-text__input {
            padding-right: 30px;
        }

        &:before {
            position: absolute;
            right: -17px;
            top: 19px;
            content: "%";
            pointer-events: none;
        }

        &.input-wrapper--error {
            color: $color-primary-red;

            &:before {
                color: $color-primary-red;
            }
        }
    }
}
