@import "../../scss/variables/Variables";

.print-and-save {
    position: absolute;
    font-family: $frutigerLight;
    margin-top: 100px;
    top: 0;
    transform: translateY(-550px);
    transition: transform 0.7s ease-out;
    margin-right: 1px;

    &__description {
        margin: 0 0 24px;
    }

    &__btn-print,
    &__btn-save {
        box-sizing: border-box;
        margin-bottom: 24px;
        width: 100%;
    }

    &__footnote {
        font-size: $footnotes;

        margin: 0;
    }

    &.active {
        transform: translateY(0);
    }
}
