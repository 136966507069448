@import "../../scss/variables/Variables";

.heading-1 {
    font-size: $headline1;
    font-family: $frutigerRegular;
    font-weight: normal;
}

.heading-2 {
    font-size: $headline2;
    font-family: $playFairDisplay;
    font-weight: 500;
    margin: 0 0 48px;
    line-height: 40px;
}

.heading-3 {
    font-size: $headline3;
    font-family: $frutigerRegular;
    font-weight: normal;
    margin: 0 0 24px;
    line-height: 37px;
}

.scrollNext {
    .heading-3 {
        &.next-section-headline {
            opacity: 0;
            transition: opacity 0.4s ease-out;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
